import React, { useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import {
  GetInvoice,
  GetShippingLabel,
  GetProductData,
  ChangePrepStatus,
  ChangeShipStatus,
} from "../helper/apiHelper";
import { useDispatch, useSelector } from "react-redux";
import { SearchContext } from "../helper/context/searchContext";
import { showToast } from "../helper/toastHelper";
import { toast } from "react-toastify";
import { CurrentUser } from "../helper/generalHelper";
import { setOrderErrorRows } from "../store/generaldata/generalDataSlice";
import GetWarehouseID from "../helper/warehouseHelper";
import PdfGenerator from './PdfGenerator';

const ActionButtons = ({
  currentTab,
  FetchOrders,
  TotalOrders,
}) => {
  const { isSearchActive, SearchOrders, toggleLoader } = useContext(SearchContext);
  const selectedRows = useSelector(
    (state) => state.generalData.orderSelectedRows
  );
  const ccv_warehouse_id = GetWarehouseID("ccv");
  const currentUser = CurrentUser();
  const dispatch = useDispatch();
  const HandleBulkInvoice = () => {
    const downloadBulkInvoice = async () => {
      console.log(456);
      if (selectedRows.length) {
        toggleLoader(true);
        try {
          const response = await GetInvoice(
            currentTab,
            "download_invoice.php",
            [{ id_order: selectedRows.toString(), user: currentUser }]
          );
          console.log(response);
          // const fileURL = response.blob();
          // window.open(fileURL, "_blank");
          fetch(response.data)
            .then(response => response.blob())
            .then(blob => {
              const url = URL.createObjectURL(blob);
              window.open(url, '_blank');
          })
          .catch(error => console.error('Error fetching PDF:', error));
        } catch (err) {
          toast.dismiss();
          showToast("Impossible de télécharger la facture", "error");
        }
        toggleLoader(false);
        //dispatch(resetOrderSelectedRows());
      } else {
        showToast("Veuillez choisir au moins une commande", "error");
      }
    };
    downloadBulkInvoice();
  };

  const HandleBulkShipping = () => {
    const downloadBulkShipping = async () => {
      if (selectedRows.length) {
        toggleLoader(true);
        try {
          const response = await GetShippingLabel(
            currentTab,
            "download_shipping_label.php",
            [{ checkbox: selectedRows.toString(), type: "0" }]
          );
          if(response.data.url) {
            fetch(response.data.url)
            .then(response => response.blob())
            .then(blob => {
              const url = URL.createObjectURL(blob);
              window.open(url, '_blank');
            })
            .catch(error => console.error('Error fetching PDF:', error));
            // const fileURL = response.data.url;
            // window.open(fileURL, "PRINT");
          } else {
            showToast(
              "Impossible de télécharger l'étiquette d'expédition",
              "error"
            );
          }
          
        } catch (err) {
          showToast(
            "Impossible de télécharger l'étiquette d'expédition",
            "error"
          );
        }
        toggleLoader(false);
        //dispatch(resetOrderSelectedRows());
      } else {
        showToast("Veuillez choisir au moins une commande", "error");
      }
    };
    downloadBulkShipping();
  };

  const HandleBulkReturn = () => {
    const downloadBulkReturn = async () => {
      if (selectedRows.length) {
        toggleLoader(true);
        try {
          const response = await GetShippingLabel(
            currentTab,
            "download_return_label.php",
            [{ checkbox: selectedRows.toString(), type: "0" }]
          );

          const fileURL = response.data;
          window.open(fileURL, "PRINT");
        } catch (err) {
          showToast(
            "Impossible de télécharger l'étiquette d'expédition",
            "error"
          );
        }
        toggleLoader(false);
        //dispatch(resetOrderSelectedRows());
      } else {
        showToast("Veuillez choisir au moins une commande", "error");
      }
    };
    downloadBulkReturn();
  };

  const HandleBulkProductData = () => {
    const downloadBulkProductData = async () => {
      if (selectedRows.length) {
        toggleLoader(true);
        let tab = currentTab;
        if(currentTab === 'CCVZalando') {
          tab = "CCV";
        } 
        try {
          const response = await GetProductData(
            tab,
            "download_product_data.php",
            [{ checkbox: selectedRows.toString(), employee_id: ccv_warehouse_id }]
          );
          const fileURL = response.data;
          window.open(fileURL, "PRINT");
        } catch (err) {
          showToast("Impossible de télécharger le tableau de picking", "error");
        }
        toggleLoader(false);
        //dispatch(resetOrderSelectedRows());
      } else {
        showToast("Veuillez choisir au moins une commande", "error");
      }
    };
    downloadBulkProductData();
  };

  const HandlePreparationEnCours = () => {
    const changePrepOrderStatus = async () => {
      if (selectedRows.length) {
        try {
          toggleLoader(true);
          await ChangePrepStatus(currentTab, "change_order_status.php", [
            { checkbox: selectedRows.toString(), status: "prep", user: currentUser },
          ]).then((resp) => {
            showToast(resp.data.msg, "info");
            isSearchActive ? SearchOrders() : FetchOrders();
            TotalOrders();
            //dispatch(resetOrderSelectedRows());
          });
          toggleLoader(false);
        } catch (err) {
          toggleLoader(false);
          showToast("Erreur survenue lors du changement d'état", "error");
        }
      } else {
        toggleLoader(false);
        showToast("Veuillez choisir au moins une commande", "error");
      }
    };
    changePrepOrderStatus();
  };

  const HandleShippedStatus = () => {
    const changeShipOrderStatus = async () => {
      if (selectedRows.length) {
        try {
          toggleLoader(true);
          await ChangeShipStatus(currentTab, "change_order_status.php", [
            { checkbox: selectedRows.toString(), status: "ship", user: currentUser },
          ]).then((resp) => {
            if(resp.data.error === 1) {
              showToast(resp.data.msg, "warning", 5000);
              const ordersLists = resp.data.orders.split(",");
              dispatch(setOrderErrorRows(ordersLists));
              isSearchActive ? SearchOrders() : FetchOrders();
              TotalOrders();
            } else {
              showToast(resp.data.msg, "info");
              isSearchActive ? SearchOrders() : FetchOrders();
              TotalOrders();
            }
            //dispatch(resetOrderSelectedRows());
          });
          toggleLoader(false);
        } catch (err) {
          toggleLoader(false);
          showToast("Erreur survenue lors du changement d'état", "error");
        }
      } else {
        toggleLoader(false);
        showToast("Veuillez choisir au moins une commande", "error");
      }
    };
    changeShipOrderStatus();
  };

  const HandlePaymentAcceptedStatus = () => {
    const changeOrderStatus = async () => {
      if (selectedRows.length) {
        try {
          toggleLoader(true);
          await ChangeShipStatus(currentTab, "change_order_status.php", [
            { checkbox: selectedRows.toString(), status: "accept", user: currentUser },
          ]).then((resp) => {
            showToast(resp.data.msg, "info");
            isSearchActive ? SearchOrders() : FetchOrders();
            TotalOrders();
            //dispatch(resetOrderSelectedRows());
          });
          toggleLoader(false);
        } catch (err) {
          toggleLoader(false);
          showToast("Erreur survenue lors du changement d'état", "error");
        }
      } else {
        toggleLoader(false);
        showToast("Veuillez choisir au moins une commande", "error");
      }
    };
    changeOrderStatus();

  }

  const HandleCCReady = () => {
    const changeCCReady = async () => {
      if (selectedRows.length) {
        try {
          toggleLoader(true);
          await ChangeShipStatus(currentTab, "change_order_status.php", [
            { checkbox: selectedRows.toString(), status: "ready", user: currentUser },
          ]).then((resp) => {
            resp.data.error === 1 ? showToast(resp.data.msg, "warning") : showToast(resp.data.msg, "info");;
            isSearchActive ? SearchOrders() : FetchOrders();
            TotalOrders();
            //dispatch(resetOrderSelectedRows());
          });
          toggleLoader(false);
        } catch (err) {
          toggleLoader(false);
          showToast("Erreur survenue lors du changement d'état", "error");
        }
      } else {
        toggleLoader(false);
        showToast("Veuillez choisir au moins une commande", "error");
      }
    };
    changeCCReady();
  };

  const HandleCCWithDraw = () => {
    const changeCCWithDraw = async () => {
      if (selectedRows.length) {
        try {
          toggleLoader(true);
          await ChangeShipStatus(currentTab, "change_order_status.php", [
            { checkbox: selectedRows.toString(), status: "withdraw", user: currentUser },
          ]).then((resp) => {
            resp.data.error === 1 ? showToast(resp.data.msg, "warning") : showToast(resp.data.msg, "info");;
            isSearchActive ? SearchOrders() : FetchOrders();
            TotalOrders();
            //dispatch(resetOrderSelectedRows());
          });
          toggleLoader(false);
        } catch (err) {
          toggleLoader(false);
          showToast("Erreur survenue lors du changement d'état", "error");
        }
      } else {
        toggleLoader(false);
        showToast("Veuillez choisir au moins une commande", "error");
      }
    };
    changeCCWithDraw();
  };

  const HandleZalandoInvoice = () => {
    const downloadZalandoInvoice = async () => {
      if (selectedRows.length) {
        toggleLoader(true);
        try {
          const response = await GetInvoice(
            currentTab,
            "download_zalando_document.php",
            [{ id_order: selectedRows.toString(), dtype : 'invoice' }]
          );
          const fileURL = response.data;
          window.open(fileURL, "PRINT");
        } catch (err) {
          toast.dismiss();
          showToast("Impossible de télécharger la facture de zalando", "error");
        }
        toggleLoader(false);
        //dispatch(resetOrderSelectedRows());
      } else {
        showToast("Veuillez choisir au moins une commande", "error");
      }
    };
    downloadZalandoInvoice();
  };

  const HandleZalandoOtherDocuments = () => {
    const downloadZalandoOtherDocuments = async () => {
      if (selectedRows.length) {
        toggleLoader(true);
        try {
          const response = await GetInvoice(
            currentTab,
            "download_zalando_document.php",
            [{ id_order: selectedRows.toString(), dtype : 'other_doc' }]
          );
          fetch(response.data)
            .then(response => response.blob())
            .then(blob => {
              const url = URL.createObjectURL(blob);
              window.open(url, '_blank');
          })
          .catch(error => console.error('Error fetching PDF:', error));
          // const fileURL = response.data;
          // window.open(fileURL, "PRINT");
        } catch (err) {
          toast.dismiss();
          showToast("Impossible de télécharger les documents de zalando", "error");
        }
        toggleLoader(false);
        //dispatch(resetOrderSelectedRows());
      } else {
        showToast("Veuillez choisir au moins une commande", "error");
      }
    };
    downloadZalandoOtherDocuments();
  };

  /* <Col md={3} className="download-button zalando">
            <Button onClick={HandleZalandoInvoice}>Télécharger factures Zalando</Button>
          </Col> */

  return (
    <Container fluid className="p-0">
      {currentTab === 'CCVZalando' ? (
        <Row className="mb-4 mt-2 justify-content-center">
          <Col md={2} className="download-button">
            <Button onClick={HandleBulkInvoice}>Télécharger les BL</Button>
          </Col>
          <Col md={3} className="download-button zalando">
            <Button onClick={HandleZalandoOtherDocuments}>
              Télécharger documents Zalando
            </Button>
          </Col>
          <Col md={3} className="download-button zalando">
            <PdfGenerator 
              currentTab={currentTab} 
            />
          </Col>
          <Col md={2} className="download-button zalando">
            <Button onClick={HandleBulkShipping}>
              Télécharger les étiquettes
            </Button>
          </Col>
          <Col
            md={2}
            onClick={HandlePreparationEnCours}
            className="download-button no-hover"
          >
            <Button>Confirmer la collecte</Button>
          </Col>
          <Col md={2} onClick={HandleShippedStatus} className="download-button no-hover">
            <Button>Valider les expéditions</Button>
          </Col>
          <Col md={3} onClick={HandlePaymentAcceptedStatus} className="download-button annuler no-hover">
            <Button>Annuler les expéditions</Button>
          </Col>
        </Row>
      ) : (
        <Row className="mb-4 mt-2">
          <Col md={2} className="download-button">
            <Button onClick={HandleBulkInvoice}>Télécharger les BL</Button>
          </Col>
          <Col md={2} className="download-button">
            <Button onClick={HandleBulkShipping}>
              Télécharger les étiquettes
            </Button>
          </Col>
          <Col md={3} className="download-button">
            <PdfGenerator 
              currentTab={currentTab} 
            />
          </Col>
          <Col
            md={2}
            onClick={HandlePreparationEnCours}
            className="download-button no-hover"
          >
            <Button>Confirmer la collecte</Button>
          </Col>
          <Col md={2} onClick={HandleShippedStatus} className="download-button no-hover">
            <Button>Valider les expéditions</Button>
          </Col>
          <Col md={3} onClick={HandlePaymentAcceptedStatus} className="download-button annuler no-hover">
            <Button>Annuler les expéditions</Button>
          </Col>
        </Row>
      )}

      {currentTab === "CCV" && (
        <>
          <Row className="mb-5 mt-2">
            <Col md={12} className="confirm-links">
              <Row>
                <Col
                  sm={12}
                  md={5}
                  lg={3}
                  onClick={HandleCCReady}
                  className="d-flex justify-content-start align-left"
                >
                  <a href="#/">Confirmer la collecte - click and collect</a>
                </Col>
                <Col
                  sm={12}
                  md={7}
                  lg={3}
                  onClick={HandleCCWithDraw}
                  className="d-flex justify-content-start align-right"
                >
                  <a href="#/">
                    Valider le retrait des commandes - click and collect
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};
export default ActionButtons;
